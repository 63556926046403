import React from 'react';
import { Link } from 'gatsby';

const NavBar = ({ navBarLinkHome, navBarLink, navBarLinkWork }) => (
  <nav
    role="navigation"
    className="flex justify-between items-center pt-3 lg:pt-24 pb-12 mx-3 lg:mx-24  tracking-widest text-sm lg:text-xl lowercase"
    aria-label="Main"
  >
    <Link to="/" className={navBarLinkHome}>
      <span className={navBarLinkHome}>florine schüschke</span>
    </Link>
    <ul className="flex list-none gap-6 lg:gap-12">
      <li className={navBarLinkWork}>
        <Link to="/work" activeClassName="active">
          Work
        </Link>
      </li>
      <li className={navBarLink}>
        <Link to="/work#about" activeClassName="active">
          About
        </Link>
      </li>
      <li className={navBarLink}>
        <Link to="/work#about" activeClassName="active">
          Contact
        </Link>
      </li>
    </ul>
  </nav>
);

export default NavBar;
