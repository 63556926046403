import React from 'react';
import { Seo } from './seo';
import NavBar from './navBar';
import '../styles/global.css';

class Template extends React.Component {
  render() {
    const { children, bgImage, navBarLink, navBarLinkHome, navBarLinkWork } =
      this.props;

    return (
      <>
        <Seo />
        <main
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100vw',
            height: '100vh',
          }}
        >
          <NavBar
            navBarLink={navBarLink}
            navBarLinkHome={navBarLinkHome}
            navBarLinkWork={navBarLinkWork}
          />
          <section className="mx-3 lg:mx-24">{children}</section>
        </main>
      </>
    );
  }
}

export default Template;
